import React, {Component} from 'react';
// import Page from '../template/Page';
import { Link } from 'react-router-dom';
import Api from '../util/Api';
import dateFormat from "dateformat";
// import Ads from '../template/Ads';
import Right from '../template/Right';
// import Search from '../template/Search';
import withRouter from '../withRouter';
// import TFourth from './../template/TFourth';
import * as Loader from "react-loader-spinner";
import Ads from '../template/Ads';
import Search from '../template/Search';

class SearchPage extends Component{
    constructor (props) {  
        super(props)

        this.state = {
            loading: true,
            response: [],
            category: props.params.id,
            title: props.params.title
        }
        this.getData();
    }

    async componentDidMount(){
        document.title = "BarBarInfo - Berita Terbarbar "+this.state.title.toUpperCase();   
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 1000);
    }

    getData(){
        let uri = "/article/searching";
        const postData = {
            category: this.state.category,
            title: this.state.title,
            offset: 0,
            limit: 25
        };
        Api(uri, postData).then(res => {
            // console.log(res)
            // if(res.ec === 0){
                this.setState({
                    response: res.object
                })
            // }
        })
        .catch(err => console.log(err));
    }

    render(){
        let state = this.state;

        // console.log(state.response)
        const data = state.loading === true ?
        (
        <div className='col-lg-12 text-center'>
            <Loader.ProgressBar type="ThreeDots" color="#F00F00" height="100%" width="10%" />
        </div>
        )
        :
        (
            <div className='col-lg-12 text-left'>
                <Search/>
                <h4>Search "{state.title}"</h4>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='row'>
                        {state.response.length > 0 ? 
                        (
                            state.response.map((res,i) =>  (
                            <div className='col-md-12' key={i}>
                                <Link reloadDocument to={{pathname: '/page/'+res.category+'/'+res.title.replace(/\s+/g, '-').toLowerCase()+''}}>
                                    <div className='row content'>
                                        <div className='col-sm-3'>
                                            <img src={res.imageUrl === "" ? "/./images/default.jpg" : res.imageUrl} alt='' className=' img-fluid'/>
                                        </div>
                                        <div className='col-sm-9'>
                                            <h5><b className='text-capitalize'>{res.title}</b></h5>
                                        </div>
                                    </div>
                                    <div className='row content-date'>
                                        <div className='col'>
                                            <i className='icon fa-regular fa-clock'></i>
                                            {dateFormat(res.createdAt, "mmm dd, yyyy HH:MM:ss")}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            ))
                        )
                        :
                        (
                            <div className='col-md-12 text-center'>
                                <h3>No Data "{state.title}"</h3>
                                <div>Back <Link to={"/"}><u>Home</u></Link></div>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        );
        
        return (
            <>
                <div className='section-bg'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8 template-4'>
                                <Ads q={""}/>
                                {data}
                            </div>

                            <div className='col-lg-4'>
                                {/* <Search/> */}
                                <Right/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(SearchPage);