import React, {Component}  from 'react';
import { Link } from 'react-router-dom';
import Api from '../util/Api';
import dateFormat from "dateformat";
import * as Loader from "react-loader-spinner";
import ProgressiveImage from 'react-progressive-graceful-image';

class TThird extends Component{
    constructor(props){
      super(props)
  
      this.state = {
        loading: true,
        response: [],
        head: props.head,
        query: props.q,
        category: props.category,
        dataRes: 0,
        firstInitSize: props.q.split("|")[1]
      }
      this.getData();
    }

    componentDidMount(){
        // setTimeout(() => {
            this.setState({
                loading: false
            });
        // }, 500);
    }

    getData(){
        let uri = "/article"+this.state.category+"/home";
        const postData = {
            query: this.state.query
        };
        Api(uri, postData).then(res => {
            this.setState({
                response: res
            })
        })
        .catch(err => console.log(err));
    }

    render(){
        let state = this.state;
        let dataRes = state.response.slice(2, state.response.length);
        let res = state.response[0];
        let res2 = state.response[1];

        const data = state.loading === true ?
        (
        <div className='col-lg-12 text-center'>
            <Loader.ProgressBar type="ThreeDots" color="#F00F00" height="100%" width="10%" />
        </div>
        )
        :
        (
            <div className='col-lg-12 text-left'>
                <div className='row title-template'>
                    <div className='col head'>{state.head}</div>
                    <div className='col read-more text-right'>
                        {window.location.pathname === "/" ? (
                            <Link reloadDocument to={this.state.category}>Read More</Link>
                        ) : (<></>)}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='row m-0'>
                            <div className='content text-center bg-light'>
                                <Link reloadDocument to={{pathname: '/page/'+res?.category+'/'+res?.titleUrl}}>
                                    {/* <img src={res?.imageUrl === "" ? "/./images/default.jpg" : res?.imageUrl} alt='' className='img-responsive img-fluid'/> */}
                                    <ProgressiveImage
                                        delay={500}
                                        src={res?.imageUrl === "" ? "/./images/default.jpg" : res?.imageUrl}
                                        placeholder="/./images/default.jpg"
                                        >
                                            {(src) => <img src={src} alt="" className={"img-responsive img-fluid parent"} />}
                                    </ProgressiveImage>
                                </Link>
                            </div>
                            <div className='row content-date'>
                                <div className='col'>
                                    <i className='icon fa-regular fa-clock'></i>
                                    {dateFormat(res?.createdAt, "mmm dd, yyyy HH:MM:ss")}
                                </div>
                            </div>
                            <div className='content-head'>
                                <Link reloadDocument to={{pathname: '/page/'+res?.category+'/'+res?.titleUrl}}>
                                    {res?.title}
                                </Link>
                            </div>
                        </div>
                        <div className='row m-0'>
                            <div className='content text-center bg-light'>
                                <Link reloadDocument to={{pathname: '/page/'+res2?.category+'/'+res2?.titleUrl}}>
                                    {/* <img src={res?.imageUrl === "" ? "/./images/default.jpg" : res?.imageUrl} alt='' className='img-responsive img-fluid'/> */}
                                    <ProgressiveImage
                                        delay={500}
                                        src={res2?.imageUrl === "" ? "/./images/default.jpg" : res2?.imageUrl}
                                        placeholder="/./images/default.jpg"
                                        >
                                            {(src) => <img src={src} alt="" className={"img-responsive img-fluid parent"} />}
                                    </ProgressiveImage>
                                </Link>
                            </div>
                            <div className='row content-date'>
                                <div className='col'>
                                    <i className='icon fa-regular fa-clock'></i>
                                    {dateFormat(res2?.createdAt, "mmm dd, yyyy HH:MM:ss")}
                                </div>
                            </div>
                            <div className='content-head'>
                                <Link reloadDocument to={{pathname: '/page/'+res2?.category+'/'+res2?.titleUrl}}>
                                    {res2?.title}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='row'>
                        {dataRes.map((res,i) =>  (
                        <div className='col-md-6' key={i}>
                            <Link reloadDocument to={{pathname: '/page/'+res.category+'/'+res.titleUrl}}>
                                {/* <div className='content-head'></div> */}
                                <div className='content child text-center bg-light'>
                                    {/* <img src={res.imageUrl === "" ? "/./images/default.jpg" : res.imageUrl} alt='' className=' img-fluid'/> */}
                                    <ProgressiveImage
                                        delay={500}
                                        src={res.imageUrl === "" ? "/./images/default.jpg" : res.imageUrl}
                                        placeholder="/./images/default.jpg"
                                        >
                                            {(src) => <img src={src} alt="" className={"img-fluid"} />}
                                    </ProgressiveImage>
                                </div>
                                <div className='row content-date'>
                                    <div className='col-lg-12'>
                                        <i className='icon fa-regular fa-clock'></i>
                                        {dateFormat(res.createdAt, "mmm dd, yyyy HH:MM:ss")}
                                    </div>
                                    <div className='col-lg-4 text-right'>
                                        {/* <i className='icon fa-regular fa-comment'></i>
                                        0 */}
                                    </div>
                                </div>
                                <div className='content-desc'>
                                    <b>{res.title}</b>
                                </div>
                            </Link>
                        </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        );
        return (
            <div className='row template-3'>
                {data}
            </div>
        )
    }
}

export default TThird;