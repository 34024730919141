import React, {Component} from 'react';
import Cookies from 'universal-cookie';
// import logo from './logo.svg';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import './App.css';
import Home from './component/Home';
// import Today from './component/Today';
import Contact from './component/Contact';
import Footer from './component/template/Footer';
import Header from './component/template/Header';
import Detail from './component/template/Detail';
import Food from './component/page/Food';
import Technology from './component/page/Technology';
import Crypto from './component/page/Crypto';
import Otomotif from './component/page/Otomotif';
import Error404 from './component/page/Error404';
import News from './component/page/News';
import Sport from './component/page/Sport';
import Movie from './component/page/Movie';
import Music from './component/page/Music';
import Novel from './component/page/Novel';
import Travel from './component/page/Travel';
import Seleb from './component/page/Seleb';
import Politic from './component/page/Politic';
import Game from './component/page/Game';
import SearchPage from './component/page/SearchPage';
import Privacy from './component/page/Privacy';
import Beauty from './component/page/Beauty';
import Health from './component/page/Health';

class App extends Component {
  constructor(props){
    super(props)
    // let login = false;

    this.state = {
      // login: login
    }
  }
  
  componentDidMount(){
  }


  render(){
    const homePage = 
    (
      <Routes>
        {/* <Route exact path="/"><Redirect to="/home" /></Route> */}
        <Route path="/">
          <Route path="" element={<Home/>}/>
          <Route path="/home" element={<Home/>}/>
        </Route>
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/food" element={<Food />} />
        <Route path="/tech" element={<Technology />} />
        <Route path="/crypto" element={<Crypto />} />
        <Route path="/otomotif" element={<Otomotif />} />
        <Route path="/news" element={<News />} />
        <Route path="/sport" element={<Sport />} />
        <Route path="/movie" element={<Movie />} />
        <Route path="/music" element={<Music />} />
        <Route path="/novel" element={<Novel />} />
        <Route path="/travel" element={<Travel />} />
        <Route path="/seleb" element={<Seleb />} />
        <Route path="/politic" element={<Politic />} />
        <Route path="/game" element={<Game />} />
        <Route path="/beauty" element={<Beauty />} />
        <Route path="/health" element={<Health />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/search">
          <Route path="" element={<Home/>}/>
          <Route path="index" element={<Home/>}/>
          <Route path=":id/:title" element={<SearchPage/>}/>
        </Route>
        {/* <Route name="page" path="/:id/:title" handler={Detail} /> */}
        <Route path="/page">
          <Route path="" element={<Home/>}/>
          <Route path="index" element={<Home/>}/>
          <Route path=":id/:title" element={<Detail/>}/>
        </Route>
        {/* 404 */}
        <Route path="*" element={<Navigate to={"/404"}/>}/>
        <Route path="/404" element={<Error404/>}/>
      </Routes>
    );
    
    const dashboard = (<div>{homePage}</div>);
    const cookies = new Cookies();
    cookies.set("plugins", "investing", {secure: true, sameSite: 'none'});
    return (
      <BrowserRouter>
        <div className="App">
          <header>
            <Header/>
          </header>
          <section>
            {dashboard}
          </section>
          <footer>
            <Footer/>
          </footer>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
