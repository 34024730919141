import React, {Component} from 'react';
// import { Slide } from 'react-slideshow-image';
// import {NavLink} from 'react-router-dom';
import Page from './template/Page';
import Api from './util/Api';
import Ads from './template/Ads';
import Right from './template/Right';
// import Search from './template/Search';

class Home extends Component{
    constructor (props) {  
        super(props)

        this.state = {
            response: [],
            title: "Home"
        }
    }

    async componentDidMount(){
        document.title = "BarBarInfo - Berita Terbarbar "+this.state.title.toUpperCase();
        this.getData();
    }

    getData(){
        let uri = "/home/get/active";
        const postData = {};
        Api(uri, postData).then(res => {
            this.setState({
                response: res
            })
        })
        .catch(err => console.log(err));
    }

    render(){
        let state = this.state;
        let response = state.response;
        
        // const slideImages = [
        //     'https://images.unsplash.com/photo-1449034446853-66c86144b0ad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80',
        //     'https://images.unsplash.com/photo-1470341223622-1019832be824?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2288&q=80',
        //     'https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80',
        //     'https://images.unsplash.com/photo-1534161308652-fdfcf10f62c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2174&q=80'
        // ]

        const homeList = response.map((res) => {
            return (res.type === 2) ? <Ads key={res.id} q={"header"} id={res.query} name={res.name}/> : <Page key={res.id} template={res.template} head={res.title} q={res.query} category={"/"+res.name}/>;
        });
        
        return (
            <>
                {/* <div className='section-latest'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <img src='/./images/banner/banner.png' alt='' className='img-responsive img-fluid'/>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className='section-bg'>
                    <div className='container'>
                        {/* <div className='row'>
                            <div className='col-lg-12'><Ads q={"1"}/></div>
                        </div> */}
                        <div className='row'>
                            <div className='col-lg-8'>
                                {homeList}
                            </div>

                            <div className='col-lg-4'>
                                {/* <Search/> */}
                                <Right/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;