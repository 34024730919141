import React, {Component}  from 'react';
import {Link} from 'react-router-dom';

class Social extends Component{
    constructor(props){
      super(props)
  
      this.state = {
      }
    }

    render(){
        return (
            <div className='text-sm-right text-center'>
                <Link to="https://twitter.com/barbarinfocom" target={"_blank"}><i className='fa-brands fa-twitter fa-beat-fade'></i></Link>
                <Link to="https://www.instagram.com/barbarinfodotcom/" target={"_blank"}><i className='fa-brands fa-instagram fa-beat-fade'></i></Link>
                <Link to="#"><i className='fa-brands fa-facebook fa-beat-fade'></i></Link>
            </div>
        )
    }
}

export default Social;