import React, {Component}  from 'react';
import {Link} from 'react-router-dom';
import Ads from './Ads';
import Api from '../util/Api';
import dateFormat from "dateformat";
import * as Loader from "react-loader-spinner";
import RandomCategory from "./../util/Random";
import ProgressiveImage from 'react-progressive-graceful-image';
// import withRouter from '../withRouter';

class Right extends Component{
    constructor(props){
        super(props)

        var page = window.location.pathname;
        var arr;

        var cat1 = RandomCategory[Math.floor(Math.random()*RandomCategory.length)];
        var cat2 = RandomCategory[Math.floor(Math.random()*RandomCategory.length)];
        if(page !== "/"){
            page = page.replace('/','');
            arr = RandomCategory.filter((rdm) => rdm !== page);

            cat1 = arr[Math.floor(Math.random()*arr.length)];
            cat2 = arr[Math.floor(Math.random()*arr.length)];
        }

        this.state = {
            cat1: cat1,
            cat2: cat2,
            cat1Response: [],
            cat1Loading: true,
            cat2Response: [],
            cat2Loading: true,
            ads: {
                name: "google",
                value: "3944157749",
                url: "",
            }
        }
    
        this.getFirstCategory();
        this.getSecondCategory();
    }
    
    componentDidMount(){
        setTimeout(() => {
            this.setState({
                cat1Loading: false,
                cat2Loading: false
            });
        }, 500);
    }

    getFirstCategory(){
        var random = Math.floor(Math.random()* 10)+5;
        let uri = "/article/"+this.state.cat1+"/home";
        const postData = {
            query: random+"|5"
        };
        Api(uri, postData).then(res => {
            this.setState({
                cat1Response: res
            })
        })
        .catch(err => console.log(err));
    }

    getSecondCategory(){
        var random = Math.floor(Math.random()* 10)+5;
        let uri = "/article/"+this.state.cat2+"/home";
        const postData = {
            query: random+"|5"
        };
        Api(uri, postData).then(res => {
            this.setState({
                cat2Response: res
            })
        })
        .catch(err => console.log(err));
    }

    render(){
        let state = this.state;
        const tab1 = state.cat1Loading === true ?
        (
        <div className='col-lg-12 text-center'>
            <Loader.ProgressBar type="ThreeDots" color="#F00F00" height="100%" width="10%" />
        </div>
        )
        :
        (
            <>
            {state.cat1Response.map((res,i) =>  (
                <div className='row most-row' key={i}>
                    <div className='col-4 most-img'>
                        {/* <img src='/./images/recent/image1.jpg' alt='' className='img-responsive img-fluid'/> */}
                        <Link reloadDocument to={{pathname: '/page/'+res.category+'/'+res.titleUrl}}>
                            {/* <img src={res.imageUrl === "" ? "/./images/default.jpg" : res.imageUrl} alt='' className='img-responsive img-fluid'/> */}
                            <ProgressiveImage
                                delay={500}
                                src={res.imageUrl === "" ? "/./images/default.jpg" : res.imageUrl}
                                placeholder="/./images/default.jpg"
                                >
                                    {(src) => <img src={src} alt="" className={"img-responsive img-fluid"} />}
                            </ProgressiveImage>
                        </Link>
                    </div>
                    <div className='col-8 most-content'>
                        <div className='row text-left'>
                            <div className='col-md-12'>
                                <b className={res.category+' sports text-capitalize'}>{res.category}</b>
                            </div>

                            <div className='col-md-12'>
                                <div className='title text-capitalize'>
                                <Link reloadDocument to={{pathname: '/page/'+res.category+'/'+res.titleUrl}}>
                                    {res.title}
                                </Link>
                                </div>
                            </div>

                            <div className='col-md-12'>
                                <div className='row date'>
                                    <div className='col-md-8'>
                                        <i className='icon fa-regular fa-clock'></i>
                                        {dateFormat(res.createdAt, "mmm dd, yyyy")}
                                    </div>
                                    <div className='col-md-4 text-right'>
                                        {/* <i className='icon fa-regular fa-comment'></i>
                                        13 */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            </>
        );

        const tab2 = state.cat2Loading === true ?
        (
        <div className='col-lg-12 text-center'>
            <Loader.ProgressBar type="ThreeDots" color="#F00F00" height="100%" width="10%" />
        </div>
        )
        :
        (
            <>
            {state.cat2Response.map((res,i) =>  (
                <div className='row most-row' key={i}>
                    <div className='col-4 most-img'>
                        {/* <img src='/./images/recent/image1.jpg' alt='' className='img-responsive img-fluid'/> */}
                        <Link reloadDocument to={{pathname: '/page/'+res.category+'/'+res.titleUrl}}>
                            {/* <img src={res.imageUrl === "" ? "/./images/default.jpg" : res.imageUrl} alt='' className='img-responsive img-fluid'/> */}
                            <ProgressiveImage
                                delay={500}
                                src={res.imageUrl === "" ? "/./images/default.jpg" : res.imageUrl}
                                placeholder="/./images/default.jpg"
                                >
                                    {(src) => <img src={src} alt="" className={"img-responsive img-fluid"} />}
                            </ProgressiveImage>
                        </Link>
                    </div>
                    <div className='col-8 most-content'>
                        <div className='row text-left'>
                            <div className='col-md-12'>
                                <b className={res.category+' text-capitalize'}>{res.category}</b>
                            </div>

                            <div className='col-md-12'>
                                <div className='title text-capitalize'>
                                <Link reloadDocument to={{pathname: '/page/'+res.category+'/'+res.titleUrl}}>
                                    {res.title}
                                </Link>
                                </div>
                            </div>

                            <div className='col-md-12'>
                                <div className='row date'>
                                    <div className='col-md-8'>
                                        <i className='icon fa-regular fa-clock'></i>
                                        {dateFormat(res.createdAt, "mmm dd, yyyy")}
                                    </div>
                                    <div className='col-md-4 text-right'>
                                        {/* <i className='icon fa-regular fa-comment'></i>
                                        13 */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            </>
        );

        
        return (
            <>
            <div className='row'>
                <div className='col-lg most'>
                    <div className='content'>
                        <ul className='nav nav-pills' role='tablist'>
                            <li className='nav-item'>
                                <a href='#tab1' className='nav-link active' data-toggle='pill'>{state.cat1.toUpperCase()}</a>
                            </li>
                            <li className='nav-item'>
                                <a href='#tab2' className='nav-link' data-toggle='pill'>{state.cat2.toUpperCase()}</a>
                            </li>
                        </ul>    
                    </div>
                    <div className='tab-content'>
                        <div id='tab1' className='tab-pane active'>
                            {tab1}
                        </div>
                        <div id='tab2' className='tab-pane fade'>
                            {tab2}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='row'>
                {/* <Tweet tweetId="841418541026877441" /> */}
                <Ads q={"sidebar"} id={this.state.ads.value} name={this.state.ads.name}/>
            </div>
            </>
        )
    }
}

export default Right;