import React, {Component}  from 'react';
import {NavLink} from 'react-router-dom';
import { Link } from 'react-router-dom';
import Ads from './Ads';
import Social from './Social';
import Api from '../util/Api';

class Header extends Component{
    constructor(props){
      super(props)
  
      this.state = {
        response: [],
        ads: {
            name: "google",
            value: "8577537321",
            url: "",
        }
      }

      this.getData();
    }

    getData(){
        let randomCategory = [
            "news",
            "politic",
            "crypto",
            "sport",
            "otomotif",
            "tech",
            "seleb"
        ]
        var cat1 = randomCategory[Math.floor(Math.random()*randomCategory.length)];
        let uri = "/article/"+cat1+"/home";
        const postData = {
            query: "5|4"
        };
        Api(uri, postData).then(res => {
            this.setState({
                response: res
            })
        })
        .catch(err => console.log(err));
    }
    _menuClick(e){
        // e.preventDefault();
        // let href = e.target.attributes.href.value;
        // console.log(href)
        // window.location.href = href;
    }

    render(){
        let state = this.state;
        // let path = window.location.pathname;
        const menu = (
            <div className='col-sm-12'>
                <div className='menu'><NavLink reloadDocument to="/" onClick={(e)=>{this._menuClick.bind(e)}}><i className="fa-solid fa-house fa-bounce"></i></NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/news" onClick={(e)=>{this._menuClick.bind(e)}}>news</NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/politic" onClick={(e)=>{this._menuClick.bind(e)}}>politic</NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/crypto" onClick={(e)=>{this._menuClick.bind(e)}}>crypto</NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/sport" onClick={(e)=>{this._menuClick.bind(e)}}>sport</NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/otomotif" onClick={(e)=>{this._menuClick.bind(e)}}>otomotif</NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/movie" onClick={(e)=>{this._menuClick.bind(e)}}>movie</NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/travel" onClick={(e)=>{this._menuClick.bind(e)}}>travel</NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/food" onClick={(e)=>{this._menuClick.bind(e)}}>food</NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/tech" onClick={(e)=>{this._menuClick.bind(e)}}>technology</NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/music" onClick={(e)=>{this._menuClick.bind(e)}}>music</NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/novel" onClick={(e)=>{this._menuClick.bind(e)}}>story</NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/seleb" onClick={(e)=>{this._menuClick.bind(e)}}>celebrity</NavLink></div>
                <div className='menu'><NavLink reloadDocument to="/game" onClick={(e)=>{this._menuClick.bind(e)}}>game</NavLink></div>
                {/* <div className='menu'><NavLink reloadDocument to="/beauty" onClick={(e)=>{this._menuClick.bind(e)}}>beauty</NavLink></div> */}
                {/* <div className='menu'><NavLink reloadDocument to="/lifestyle" onClick={this._menuClick.bind(this)}>lifestyle</NavLink></div> */}
                {/* <div className='menu'><NavLink reloadDocument to="/contact" onClick={this._menuClick.bind(this)}>contact</NavLink></div> */}
            </div>
        );

        const data = (
            state.response.map((res,i) =>  (
                <Link reloadDocument to={{pathname: '/page/'+res.category+'/'+res.titleUrl}} key={i}>
                    <li className='news-item text-capitalize'>{res.title}</li>
                </Link>
            ))
        )
        return (
            <div>
                <div className='top-header'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-8 col-sm-12 sticker'>
                                <ul className='news-demo-down-auto'>
                                    {data}
								</ul>
                            </div>
                            <div className='col-md-4 col-sm-12 social'>
                                <Social/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='top-logo'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 logo'>
                                <Link to={"/"}>
                                    <span className='logo-one'><i className='fa-regular fa-newspaper'></i>BARBAR</span>
                                    <span className='logo-two'>INFO</span>
                                </Link>
                            </div>
                            <div className='col-lg-8 ads-banner'>
                                <Ads q={"none"} id={this.state.ads.value} name={this.state.ads.name}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='top-menu'>
                    <div className='container'>
                        <div className='row'>
                            {menu}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;