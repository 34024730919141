import React, {Component}  from 'react';
import TFirst from './TFirst';
import TFourth from './TFourth';
import TSecond from './TSecond';
import TThird from './TThird';

class Page extends Component{
    constructor(props){
      super(props)
  
      this.state = {
        template: props.template,
        head: props.head,
        query: props.q,
        category: props.category
      }
    }

    renderSwitch(param) {
        switch(param) {
            case 'col6':
            default:
                return <TFirst head={this.state.head} q={this.state.query} category={this.state.category}/>;
            case 'col4':
                return <TSecond head={this.state.head} q={this.state.query} category={this.state.category}/>;
            case 'col6-6':
                return <TThird head={this.state.head} q={this.state.query} category={this.state.category}/>;
            case 'col12':
                return <TFourth head={this.state.head} q={this.state.query} category={this.state.category}/>;
        }
    }
    render(){
        let state = this.state;
        let template = state.template;

        return (
            <>
                {this.renderSwitch(template)}
            </>
        )
    }
}

export default Page;