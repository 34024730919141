import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class Error404 extends Component{
    constructor (props) {  
        super(props)

        this.state = {
            response: [],
            title: "Page Not Found 404"
        }
    }

    async componentDidMount(){
        document.title = this.state.title;
    }

    render(){
        return (
            <>
                <div className='error404'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h1 className=' text-center'>
                                    404
                                    <i className="fa-solid fa-plug-circle-xmark fa-bounce"></i>
                                </h1>
                                <h2>Opps! Page Not Found</h2>

                                <h4>Return <NavLink reloadDocument to="/"><u>Home</u></NavLink></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Error404;