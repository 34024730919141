import React, {Component} from 'react';
import Page from '../template/Page';
import Api from '../util/Api';
import Ads from '../template/Ads';
import Right from '../template/Right';
import Search from '../template/Search';

class Sport extends Component{
    constructor (props) {  
        super(props)

        this.state = {
            response: [],
            title: "sport"
        }
    }

    async componentDidMount(){
        document.title = "BarBarInfo - Berita Terbarbar "+this.state.title.toUpperCase();
        this.getData();
    }

    getData(){
        let uri = "/content/"+this.state.title;
        const postData = {
            "status": 1
        };
        Api(uri, postData).then(res => {
            this.setState({
                response: res
            })
        })
        .catch(err => console.log(err));
    }

    render(){
        let state = this.state;
        let response = state.response;

        const list = response.map((res) => {
            return (res.type === 2) ? <Ads key={res.id} q={res.query}/> : <Page key={res.id} template={res.template} head={res.title} q={res.query} category={"/"+this.state.title}/>;
        });
        
        return (
            <>
                <div className='section-bg'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8'>
                                {list}
                            </div>

                            <div className='col-lg-4'>
                                <Search/>
                                <Right/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Sport;