import React, {Component}  from 'react';
// import { useSearchParams } from "react-router-dom";
// import {withRouter, RouteComponentProps} from "react-router";
import {Link} from 'react-router-dom';
import Api from '../util/Api';
import withRouter from '../withRouter';
import Ads from './Ads';
import Right from './Right';
import dateFormat from "dateformat";
import * as Loader from "react-loader-spinner";
import ProgressiveImage from 'react-progressive-graceful-image';

class Detail extends Component{
    constructor(props){
        super(props)

        this.state = {
            loading: true,
            response: [],
            category: props.params.id,
            title: props.params.title,
            ads: {
                name: "google",
                value: "8577537321",
                url: "",
            }
        }
        this.getData();
    }

    async componentDidMount(){
        let title = "BarBarInfo - Berita Terbarbar "+this.state.title
        document.title = title;
        // setTimeout(() => {
            this.setState({
                loading: false
            });
        // }, 500);
    }
    
    getData(){
        let uri = "/article/"+this.state.category+"/detail";
        let title = this.state.title
        const postData = {
            titleUrl: title
        };
        Api(uri, postData).then(res => {
            const search = document.location.search;
            const param = new URLSearchParams(search).get("to");
            if(res.status === 1 || param === "preview"){
                this.setState({
                    response: res
                },() => {})
            }else{
                // window.location = "/404";
            }
        })
        .catch(err => console.log(err));
    }

    render(){
        let state = this.state;

        // const fileData = {
        //     id: state.response.id,
        //     view: 1
        // }

        // const jsonData = JSON.stringify(fileData);

        // console.log(jsonData)

        // var blob = new Blob(["Welcome to Websparrow.org."],{ type: "text/plain;charset=utf-8" });
        // FileSaver.saveAs(blob, "static.txt");
        // fs.writeFile(state.response.id+".json", jsonData, (error) => {
        //     if (error) {
        //         console.error(error);
        //         throw error;
        //     }
        //     console.log(state.response.id+".json written correctly");
        // });

        const sharedButton = (
            <>
                <Link className='tw' to={"https://twitter.com/share?url=www.barbarinfo.com/page/"+state.category+"/"+state.title+"&text="+state.response.title} target='_blank'><i className="fa-brands fa-twitter"></i></Link>
                <Link className='fb' to={"https://www.facebook.com/sharer/sharer.php?u=www.barbarinfo.com/page/"+state.category+"/"+state.title} target='_blank'> <i className="fa-brands fa-facebook"></i> </Link>
                <Link className='wa' to={"https://api.whatsapp.com/send?text=www.barbarinfo.com/page/"+state.category+"/"+state.title} target='_blank'><i className="fa-brands fa-whatsapp"></i></Link>
            </>
        );
        const data =
        (
            <div className='row detail'>
                <div className='col-lg-8'>
                    <Ads q={"none"} id={this.state.ads.value} name={this.state.ads.name}/>
                    {/* Share */}
                    {
                        state.loading === true ?
                        (
                        <div className='col-lg-12 text-center'>
                            <Loader.ProgressBar type="ThreeDots" color="#F00F00" height="100%" width="10%" />
                        </div>
                        )
                        :
                        (
                            <>
                                <div className='detail-head text-center'>
                                    <h2>{state.response.title}</h2>
                                    <div className='datetime'>
                                        {dateFormat(state.response.createdAt, "DDD, dd mmm yyyy HH:MM")} WIB
                                    </div>
                                </div>
                                <div className='col-12 font-shared'>{sharedButton}</div>
                                <div className='detail-content'>
                                    {/* <img src={state.response.imageUrl === "" ? "/./images/default.jpg" : state.response.imageUrl} alt='' className='img-responsive img-fluid'/> */}
                                    <ProgressiveImage
                                        delay={500}
                                        src={state.response.imageUrl === "" ? "/./images/default.jpg" : state.response.imageUrl}
                                        placeholder="/./images/default.jpg"
                                        >
                                            {(src) => <img src={src} alt="" className={"img-responsive img-fluid"} />}
                                    </ProgressiveImage>
                                </div>
                                <div className='detail-desc'>
                                    <div dangerouslySetInnerHTML={{__html: state.response.content}}/>
                                </div>
                                <div className='col-12 font-shared text-right'>{sharedButton}</div>
                            </>
                        )
                    }
                    <Ads q={"none"} id={this.state.ads.value} name={this.state.ads.name}/>
                    <div id='comment'></div>
                </div>
                <div className='col-lg-4'>
                    <Right/>
                </div>
            </div>
        );
        return (
            <div className='container'>
                <div className='row section-bg'>
                    <div className='col-lg-12'>
                        {data}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Detail);