import React, {Component} from 'react';

class Contact extends Component{
    constructor(props){
        super(props)

        this.state = {
            title: "Contact",
            ip: ""
        }
    }

    async componentDidMount(){
        document.title = this.state.title;
    }

    render(){
        return (
            <div>
                <div className='section-latest'>
                    Contact
                </div>
            </div>
        )
    }
}
export default Contact