import React, {Component}  from 'react';

class Search extends Component{
    constructor(props){
        super(props)
        
        this.state = {
            text : "",
            category: window.location.pathname.split("/")
        }
    }
    // /page/'+res.category+'/'+res.title.replace(/\s+/g, '-').toLowerCase()+'
    handleSubmit = (e) => {
        e.preventDefault();
        var category = window.location.pathname;
        var text = this.state.text;
        
        if(text.length > 0) {
            if(category.split("/")[1] === "search"){
                var split = category.split("/");
                var url = "/"+split[1]+"/"+split[2]+"/"+text
                window.location.href = url;
            }else{
                window.location.href = "/search"+category+"/"+text; 
            }
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            text: e.target.value
        })
    }

    render(){
        return (
            <>
            <div className='row'>
                <div className='col-lg search'>
                    <div className='content'>
                        <form className='subscribe' onSubmit={this.handleSubmit}>
                            <div className='input-group'>
                                <input typeof='text' id="search" className='form-control text-capitalize' value={this.value} placeholder={"cari "+this.state.category[1]+" di sini"} onChange={(e) => this.handleChange(e)}/>
                                <button typeof='submit' className='btn btn-danger'><i className="fa fa-magnifying-glass fa-flip"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default Search;