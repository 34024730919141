import React, {Component}  from 'react';
// import GoogleAd from './../util/GoogleAd';
import AdSense from 'react-adsense';

class Ads extends Component{
    constructor(props){
      super(props)
  
      this.state = {
        pub: "ca-pub-4424481234608477",
        name: props.name,
        slot: props.id,
        head: props.head,
        query: props.q
      }
    }
    // ins
    // style= display: 'block', width: width || 'auto', height: height || 'auto'
    // data-ad-format rectangle
    // data-full-width-responsive true
    // data-ad-client ca-pub-XXX
    // data-ad-slot dataAdSlot
    
    renderAds(){
        switch (this.state.query) {
            case "sidebar":
                return (
                    <div className='bg'>
                        {/* <i>Advertisement</i> */}
                        <AdSense.Google
                            slot={this.state.slot}
                            format="auto"
                            style={{ display: 'block' }}
                            responsive='true'
                            client={this.state.pub}/>
                    </div>
                );
            case "header":
                return (
                    <div className='bg'>
                        {/* <i>Advertisement</i> */}
                        <AdSense.Google
                            slot={this.state.slot}
                            format="fluid"
                            style={{display:"inline-block",width:"100%",height:"100%"}}
                            layout="-fc+5g+70-cl-1m"
                            responsive='true'
                            client={this.state.pub}/>
                    </div>
                );
            default:
                return (
                    <div className='bg'>
                        <i>Advertisement</i>
                        <img src={'/./images/ads/ads1.jpg'} alt='' className='img-responsive img-fluid'/>
                    </div>
                );
        }
    }
    render(){
        const ads = this.renderAds();

        return (
            <div className='ads'>
                {ads}
            </div>
        )
    }
}

export default Ads;