import React, {Component}  from 'react';
import {Link} from 'react-router-dom';
import Api from '../util/Api';
import dateFormat from "dateformat";
import * as Loader from "react-loader-spinner";
import ProgressiveImage from 'react-progressive-graceful-image';

class TSecond extends Component{
    constructor(props){
      super(props)
  
      this.state = {
        loading: true,
        response: [],
        head: props.head,
        query: props.q,
        category: props.category,
        dataRes: 0,
        firstInitSize: props.q.split("|")[1]
      }
      this.getData();
    }
    
    componentDidMount(){
        // setTimeout(() => {
            this.setState({
                loading: false
            });
        // }, 500);
    }

    loadMoreData(e){
        e.preventDefault()
        let uri = "/article"+this.state.category+"/home";
        const postData = {
            query: this.state.dataRes+"|10"
        };
        Api(uri, postData).then(res => {
            // console.log(res.length)
            var size = 0;
            if(res.length >= 10){
                size = this.state.dataRes+res.length
            }
            this.setState({
                response: this.state.response.concat(res),
                dataRes: size
            },() => {});
        })
        .catch(err => console.log(err));
    }

    getData(){
        let uri = "/article"+this.state.category+"/home";
        const postData = {
            query: this.state.query
        };
        Api(uri, postData).then(res => {
            var size = 0;
            if(res.length >= 10){
                size = this.state.dataRes+res.length
            }
            this.setState({
                response: res,
                dataRes: size
            })
        })
        .catch(err => console.log(err));
    }

    render(){
        let state = this.state;

        const data = state.loading === true ?
        (
        <div className='col-lg-12 text-center'>
            <Loader.ProgressBar type="ThreeDots" color="#F00F00" height="100%" width="10%" />
        </div>
        )
        :
        (
            <div className='col-md-12 text-left'>
                <div className='row title-template'>
                    <div className='col head'>{state.head}</div>
                    <div className='col read-more text-right'>
                        {window.location.pathname === "/" ? (
                            <Link reloadDocument to={this.state.category}>Read More</Link>
                        ) : (<></>)}
                    </div>
                </div>
                <div className='row'>
                    {state.response.map((res,i) =>  (
                    <div className='col-sm-4 mb-4' key={i}>
                        <Link reloadDocument to={{pathname: '/page/'+res.category+'/'+res.titleUrl}}>
                            <div className='content text-center bg-light'>
                                {/* <Link reloadDocument to={{pathname: '/page/'+res.category+'/'+res.title.replace(/\s+/g, '-').toLowerCase()+''}}> */}
                                    {/* <img src={res.imageUrl === "" ? "/./images/default.jpg" : res.imageUrl} alt='' className='img-responsive img-fluid'/> */}
                                    <ProgressiveImage
                                        delay={500}
                                        src={res.imageUrl === "" ? "/./images/default.jpg" : res.imageUrl}
                                        placeholder="/./images/default.jpg"
                                        className={"img-responsive img-fluid"}
                                        >
                                            {(src) => <img src={src} alt="" className={"img-responsive img-fluid"}/>}
                                    </ProgressiveImage>
                                {/* </Link> */}
                            </div>
                            <div className='row content-date'>
                                <div className='col'>
                                    <i className='icon fa-regular fa-clock'></i>
                                    {dateFormat(res.createdAt, "mmm dd, yyyy HH:MM:ss")}
                                </div>
                                {/* <div className='col-md text-right'>
                                    <i className='icon fa-regular fa-comment'></i>
                                    0
                                </div> */}
                            </div>
                            <div className='content-head'>
                                {res.title}
                            </div>
                            <div className='content-desc'>
                                {/* <div dangerouslySetInnerHTML={{__html: res.content.substring(0,150)}}/>
                                <Link reloadDocument to={{pathname: '/page/'+res.category+'/'+res.title.replace(/\s+/g, '-').toLowerCase()+''}}>                                
                                    <i>Read More</i>
                                </Link> */}
                            </div>
                        </Link>
                    </div>
                    ))}
                </div>
                {window.location.pathname !== "/" ? (
                    <div className='my-3 text-center'>
                        <div className='col-md-3 col-sm-12 btn btn-md btn-danger' onClick={(e) => {this.loadMoreData(e)}}>More</div>
                    </div>
                ):(<></>)}
            </div>
        );
        return (
            <div className='row template-2'>
                {data}
            </div>
        )
    }
}

export default TSecond;