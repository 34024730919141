const object = {
    category : [
        "news",
        "politic",
        "crypto",
        "sport",
        "otomotif",
        // "movie",
        "travel",
        "food",
        "tech",
        "music",
        // "novel",
        "seleb",
        // "game"
    ]
}
export default object.category;