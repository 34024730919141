import React, {Component}  from 'react';
import {NavLink, Link} from 'react-router-dom';
import Social from './Social';

class Footer extends Component{
    constructor(props){
      super(props)
  
      this.state = {
      }
    }

    render(){
        // let state = this.state;

        return (
            <div>
                <div className='top-footer'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-5 col-sm-12 text-left'>
                                <h4 className='text-left'>
                                    <span className='logo-one'><i className='fa-regular fa-newspaper'></i>BARBAR</span>
                                    <span className='logo-two'>INFO</span>
                                </h4>
                                <div className='contact'>
                                    <i className='fa-regular fa-envelope'></i> contact@barbarinfo.com
                                </div>
                                {/* <div className='contact'>
                                    <i className='fa-brands fa-whatsapp'></i>
                                    081294780750
                                </div>
                                <div className='contact'>
                                    <i className="fa-regular fa-envelope"></i>
                                    bob91vic@gmail.com
                                </div>
                                <div className='contact'>
                                    <i className='fa-regular fa-envelope'></i>
                                    bob91vic2@gmail.com
                                </div>
                                <div className='contact'>
                                    <i className='fa-regular fa-envelope'></i>
                                    bob91vic3@gmail.com
                                </div> */}
                            </div>
                            <div className='col-md-3 col-sm-12 footer-category'>
                                <h5 className='text-left'>Category</h5>
                                <ul>
                                    <li>
                                        <i className='fa-regular fa-circle-dot'></i>
                                        <NavLink to="/news">News</NavLink>
                                    </li>
                                    <li>
                                        <i className='fa-regular fa-circle-dot'></i>
                                        <NavLink to="/sport">Sports</NavLink>
                                    </li>
                                    {/* <li>
                                        <i className='fa-regular fa-circle-dot'></i>
                                        <NavLink to="#">Fashion</NavLink>
                                    </li> */}
                                    <li>
                                        <i className='fa-regular fa-circle-dot'></i>
                                        <NavLink to="/tech">Technology</NavLink>
                                    </li>
                                    <li>
                                        <i className='fa-regular fa-circle-dot'></i>
                                        <NavLink to="/crypto">Cryptocurrency</NavLink>
                                    </li>
                                    <li>
                                        <i className='fa-regular fa-circle-dot'></i>
                                        <NavLink to="/travel">Travel</NavLink>
                                    </li>
                                    <li>
                                        <i className='fa-regular fa-circle-dot'></i>
                                        <NavLink to="/food">Foods</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 col-sm-12'>
                                <h5 className='text-left'>Popular tags</h5>
                                <div>
                                    <b>News</b>
                                    <b>Politic</b>
                                    <b>Crypto</b>
                                    <b>Technology</b>
                                    <b>Sport</b>
                                    <b>Food</b>
                                    <b>Celebrity</b>
                                </div>
                            </div>
                        </div>

                        <div className='row footer-line'></div>

                        <div className='row footer-subscribe'>
                            <div className='col-lg-3'></div>
                            
                            <div className="col-lg-6">
                                {/* <h4>NEWSLETTER</h4>
                                <form className='subscribe'>
                                    <div className='input-group'>
                                        <input typeof='email' id='email' className='form-control' placeholder='ENTER YOUR EMAIL' autoComplete="off"/>
                                    </div>
                                    <div className='text-right'>
                                        <button typeof='submit' className='btn btn-danger'>SUBSCRIBE</button>
                                    </div>
                                </form> */}
                            </div>

                            <div className='col-lg-3'></div>
                        </div>
                    </div>
                </div>
                <div className='bottom-footer'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-8 text-left'>
                                barbarinfo.com | All Right Reserved 2023
                                <Link to="/privacy"> Privacy Policy</Link>.
                            </div>
                            <div className='col-sm social'>
                                <Social/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;